<template>
  <div role="main">
    <div class="header">
      <div class="logo-wrapper">
        <a class="gohome" href="/"><img class="header-logo" :src="require('@/assets/PRINT_RES-Goodyear logo-yellow-108C.png')" alt="GoodyearPass logo" /></a>
      </div>
      <div class="burger-menu hide-large" v-if="showMobileMenu" v-on:click="toggleMenu()">
        <div class="menu-line" style="margin-top: 4px;"></div>
        <div class="menu-line" style="margin-top: 7px;"></div>
        <div class="menu-line" style="margin-top: 7px;"></div>
      </div>
    </div>
    <div class="dropdown-menu-wrapper" v-if="$auth.isAuthenticated">
      <div v-if="showMenu" class="dropdown-menu hide-large">
        <ul>
          <li class="menu-item" style="padding: 20px 0px;">
            <div class="logo"><img :src="require('@/assets/GYPass_Logo_Blue.png')" width="150" alt="GoodyearPass logo" /></div>
          </li>
          <li class="menu-item">
            <div class="userIcon">{{ $store.state.user.initials }}</div>
            <div class="userName">{{ $store.state.user.displayName }}</div>
          </li>
          <li class="menu-item" style="background-color: #0d2f4223; padding: 20px 0px;">
            <button class="loginButton hide-large" v-if="!$auth.isAuthenticated" v-on:click="$auth.loginWithRedirect()">Sign In</button>
            <button class="loginButton hide-large" v-if="$auth.isAuthenticated" v-on:click="$auth.logout()">Sign Out</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showMenu: false
    }
  },
  methods : {
    toggleMenu: function() {
      if(this.showMenu) {
         this.showMenu = false;
      } else if(!this.showMenu) {
        this.showMenu = true;
      }
    }
  },
  computed: {
    showMobileMenu: function() {
      return this.$store.getters.auth;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  color: #0D2F42;
  font-family: 'Barlow', sans-serif;
}

.header {
  width: 100%;
  height: 52px;
  background-color: #004EA8;
  z-index: 100;
}

.logo {
  text-align: center;
}

.logo-wrapper {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}

.header-logo {
  margin-left: 20px;
  height: 30px;
}

.userIcon {
  width: 50px;
  height: 50px;
  background-color: #C43916;
  color: white;
  font-size: 22px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  border-radius: 80px;
  float: left;
  margin-right: 20px;
}

.userName {
  font-size: 20px;
  font-weight: 500;
  color: #0D2F42;
  margin-top: 10px;
  padding-bottom: 10px;
}

.burger-menu {
  float: right;
  width: 30px;
  height: 30px;
  margin-top: 11px;
  margin-right: 11px;
  cursor: pointer;
}

@keyframes openMenu {
  from {
    top: -100%;
    z-index: 1;
  }
  to {
    top: 0px;
    z-index: 1;
  }
}

.dropdown-menu-wrapper {
  position: absolute;
  display: block;
  width: 100%;
  height: 280px;
  top: 50px;
  overflow: hidden;
}

.dropdown-menu {
  position: absolute;
  display: block;
  width: 100%;
  background-color: white;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.452) 0px 0px 10px;
  border-radius: 0;
  border: none;
  top: 0;

  animation-name: openMenu;
  animation-duration: 0.25s;
}

.menu-line {
  width: 100%;
  background-color: white;
  height: 3px;
  border-radius: 3px;
}

ul {
  list-style-type: none;
  margin:0;
  padding:0;
}

.menu-item {
  font-size: 18px;
  font-weight: 500;
  color: #0D2F42;
  border-bottom: #0d2f4223 2px solid;
  padding: 20px;
}

.B2Cbutton {
  color: #004EA8;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: white;
  border: none;
  border-radius: 18px;
  padding: 4px 14px;
}

.B2Cbutton:hover {
  opacity: 0.5;
}

.loginButton {
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  color: #004EA8;
  border-radius: 20px;
  border: none;
  padding: 4px 14px;
  transition: 0.15s;
  margin: auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .hide-large {
    display:  block;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .hide-large {
    display:  block;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .hide-large {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .hide-large {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .hide-large {
    display: none;
  }
}
</style>
