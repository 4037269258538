<template>
  <Modal @close="close">
    <form enctype="multipart/form-data" novalidate>
      <h3 style="margin-bottom: 20px;">Upload members</h3>
      <strong style="text-decoration: underline;">File requirements</strong>
      <ol style="margin-bottom: 20px; font-size: 14px;">
        <li>File type must be <strong>.csv</strong></li>
        <li>No <strong>spaces</strong> or <strong>empty values</strong></li>
      </ol>
      <div class="dropbox" v-if="parsedUsers.length==0">
        <input type="file" :disabled="fileInputIsLoading" v-on:change="processFile($event);" accept=".csv" class="input-file" />
          <p>
            Drag your file here to begin<br> or click to browse
          </p>
      </div>
      <p v-if="fileInputIsLoading">
        <b-icon icon="arrow-repeat" animation="spin-pulse"></b-icon> Uploading file...
      </p>
    </form>
    <h4 class="parsed-users-table-title" v-if="parsedUsers.length>0">
      <b-icon icon="exclamation-circle" style="margin-right: 10px;"></b-icon>Please review emails before inviting members
    </h4>
    <div class="parsed-users-table-wrapper" v-if="parsedUsers.length>0">
      <table class="parsed-users-table">
        <caption style="display: none;"></caption>
        <th></th>
        <tr v-for="parsedUser in parsedUsers" v-bind:key="parsedUser">
          <td>
            {{ parsedUser }}
          </td>
        </tr>
      </table>
    </div>

    <div><strong>Choose Roles</strong>
      <VueMultiselect
        v-model="selectedRoles"
        :options="appRoles"
        :multiple="true"
        :close-on-select="true"
        placeholder="Pick some"
        label="name"
        track-by="name"
        :disabled="addNewMembersLoading"
      />
    </div>

    <div><strong>Choose Permissions</strong>
      <VueMultiselect
        v-model="selectedPermissions"
        :options="appPermissions"
        :multiple="true"
        :close-on-select="true"
        placeholder="Pick some"
        label="label"
        track-by="label"
        :disabled="addNewMembersLoading"
      />
    </div>    

    <div style="margin-top: 20px;">
      <!-- Loading message at bottom of modal when groups members are loading -->
      <div class="addNewMembersLoading" v-if="addNewMembersLoading">
        <div class="d-flex load-wrapper">
          <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
          <div class="loadingMsg">Loading...</div>
        </div>
      </div>

      <div v-if="bulkInviteError" class="bulkInviteError">
        <b-icon icon="exclamation-circle" animation="fade" font-scale="1.25" style="margin-right: 10px;"></b-icon>
        <span>
          <strong>There was an error inviting members. </strong>
          Please review your file ( {{ uploadFileName }} ) and ensure it complies with the requirements.
        </span>
      </div>
    </div>



    <template v-slot:buttons>
      <button v-if="!bulkUploadComplete" class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close">Cancel</button>
      <button v-if="!bulkUploadComplete" class="modal-button" :disabled="parsedUsers == null || parsedUsers.length == 0 || fileInputIsInitial || addNewMembersLoading" v-on:click="bulkInvite">Upload Members</button>
      <button v-if="bulkUploadComplete" class="modal-button" v-on:click="close">Ok</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import axios from 'axios';
import { apiPath } from '../../authConfig';
axios.defaults.baseURL = apiPath;
import Papa from 'papaparse';
import VueMultiselect from 'vue-multiselect'

export default {
  name: "BulkInviteModal",
  components: {
    Modal,
    VueMultiselect 
  },
  data: function () {
    return {
      fileInputIsInitial:true,
      fileInputIsLoading:false,
      uploadFileName: '',
      parsedUsers:[],
      bulkInviteError:false,
      bulkUploadComplete:false,
      addNewMembersLoading: false,
      selectedRoles: [],
      appRoles: [],
      selectedPermissions: [],
      appPermissions: [],
      apis: []      
    }
  },
  mounted: async function () {
      this.addNewMembersLoading = true;
      
      await axios.get('/adminportal/applications/' + this.$store.state.groupId + '/roles', {
        headers: { Authorization: this.$store.state.token }
      })
      .then(response => {
        this.appRoles = response.data.result.roles;
      });
      
      await axios.get('/adminportal/applications/' + this.$store.state.groupId + '/apis', {
        headers: { Authorization: this.$store.state.token }
      })
      .then(response => {
        this.apis = response.data.result.apis;
      });

      let permissions = [];
      await axios.get('/adminportal/applications/' + this.$store.state.groupId + '/permissions', {
          headers: { Authorization: this.$store.state.token }
        })
        .then(response => {
          permissions = response.data.result.permissions;
        });      
      
        this.appPermissions = [];
        for (let permission of permissions) {
          for (let api of this.apis) {
            if(Object.keys(permission)[0] === api.id) {
              if(api.scopes) {
                for(let apiscope of api.scopes) {
                  this.appPermissions.push({"label": apiscope.value + " : " + api.name, "name": apiscope.value, "description": apiscope.description, "api": api.name, "apiIdentifier": api.identifier});
                }
              }
            }
          }
        }

      this.addNewMembersLoading = false;
  },
  methods: {
    processFile: function(csv) {
      this.fileInputIsLoading = true;
      this.bulkUploadComplete = false;
      this.uploadFileName = csv.target.files[0].name;
      Papa.parse(csv.target.files[0], {
        complete: (results) => {
          let uploadedResults = results.data.flat(1); // Flatten array
          let uniqueEmails = [...new Set(uploadedResults)]; // Remove duplicate emails
          this.parsedUsers= uniqueEmails.filter((str) => str !== ''); // Remove empty/blank
        }
      });
      this.fileInputIsLoading = false;
      this.fileInputIsInitial = false;
    },
    bulkInvite: async function() {

      this.addNewMembersLoading = true;
      let roles = this.selectedRoles.map(role => role.name);
      let permissions = [];
      for (const selectedPermission of this.selectedPermissions) {
        permissions.push({"permission_name" : selectedPermission.name, "resource_server_identifier": selectedPermission.apiIdentifier});
      }

      let data = {
        "emails": this.parsedUsers,
        "roles": roles,
        "permissions": permissions
      }

      await axios.post('/adminportal/applications/' + this.$store.state.groupId + '/members/bulk', data,
      {
        headers:{
          Authorization: this.$store.state.token,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
          this.bulkInviteSuccess = true;
          this.bulkUploadComplete = true;
          this.parsedUsers = [];
          this.fileInputIsInitial = true;
          this.$emit('bulk-invite-completed');
          this.addNewMembersLoading = false;          
          this.$emit('bulk-result-modal', response.status, response.data.result.messageId, 'bulk-invite-modal');
          this.$emit('close');
      })
      .catch(() => {
        this.bulkInviteError = true;
        this.bulkUploadComplete = true;
        this.parsedUsers = [];
        this.fileInputIsInitial = true;
        this.addNewMembersLoading = false;
      })
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  .dropbox {
    outline: 2px dashed #0D2F42;
    border-radius: 20px;
    outline-offset: -10px;
    background: #0d2f4207;
    color: #0D2F42;
    padding: 10px 10px;
    min-height: 100px;
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    max-height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: #0d2f421a;
  }
  
  .dropbox p {
    text-align: center;
    padding: 10px 0;
  }

  .parsed-users-table-wrapper {
    max-height: 200px;
    overflow: scroll;
  }

  .parsed-users-table-title {
    color: #C50202;
    margin: 20px 0px;
  }

  .parsed-users-table {
    width: 100%;
  }

  .parsed-users-table tr:nth-child(odd) {
    background-color: rgba(13, 47, 66, 0.2);
  }

  .parsed-users-table tr:nth-child(even) {
    background-color: rgba(13, 47, 66, 0.05);
  }

  .parsed-users-table td {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .bulkInviteError {
    color: #C50202;
  }

  .load-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: -18px;
  }

  .loadingMsg {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
  }

</style>
