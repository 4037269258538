<template>
  <div class="access-denied">
      <b-container>
        <b-row>
          <b-col sm="12">
            <h1>Sorry about that - you do not have the correct permissions to access the portal.</h1>
            <p>Contact the application administrator and request to be invited in GoodyearPass.</p>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'access-denied',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.access-denied {
  background-color: #fff;
  min-height: 500px;
  padding-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}
h1 {
  font-family: 'Barlow', Arial, sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #000;
}
.btn.gy-primary {
  color: #000;
}
</style>
