<template>
    <div class="footer-text">© {{new Date().getFullYear()}} The Goodyear Tire & Rubber Company  | 
        <a id="touLink" class="gy-footer-link" href="#" target="_blank" rel="noopener">Terms & Conditions and Privacy Policy</a>
    </div>
</template>

<script>
export default {
  async mounted() {
    const currentDomain = window.location.origin;
    let touHref = "/termsofuse";
    if(currentDomain.includes("manage")) {
      touHref = currentDomain.replace("manage", "portal") + touHref;
    }
    const element = document.getElementById('touLink');
    element.href = touHref;
  }
}
</script>

<style scoped>
.footer-text {
    font-size: 14px;
    font-weight: 400;
    color: #0D2F42;
    text-align: center;
}
</style>