<template>
  <Modal @close="closeModal">
    <h3 v-if="bulkResultOperationType==='bulk-invite-modal'" style="text-align: center">Bulk Upload</h3>
    <h3 v-else style="text-align: center">Bulk Remove</h3>
    <div
      v-if="isSuccessResult"
      style="text-align: center; border: #0d2f42 1px solid"
    >
      <b-row>
        <b-col style="margin-top: 10px" cols="12" sm="12">
          <b-icon icon="envelope" style="text-align: center" font-scale="1.8"></b-icon>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin-top: 10px; margin-right: 10px" cols="12" sm="12">
          <p style="font-size: 14px; font-weight: bold">
            Your request {{ bulkResultMessageId }} to add members to this
            app was submitted. Please check your email for results.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin-top: 10px; margin-left: 10px" cols="12" sm="12">
          <b-icon icon="info-circle" style="margin-right: 10px; float: left" font-scale="1.2"></b-icon>
          <p style="font-size: 14px; margin-right: 10px">
            Please note that the processing time may vary depending on the file
            size and the current demand. It could take some time before we can
            provide you with a response.
          </p>
        </b-col>
      </b-row>
    </div>
    <div v-else style="text-align: center; border: #0d2f42 1px solid">
      <b-row>
        <b-col style="margin-top: 10px" cols="12" sm="12">
          <b-icon icon="file-earmark-x" style="text-align: center" font-scale="1.8"></b-icon>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin-top: 10px; margin-right: 10px" cols="12" sm="12">
          <p style="font-size: 14px; font-weight: bold">
            An error has occurred when submitted request
            {{ bulkResultMessageId }}. Please try again.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin-top: 10px; margin-left: 10px" cols="12" sm="12">
          <b-icon icon="info-circle" style="margin-right: 10px; float: left" font-scale="1.2"></b-icon>
          <p style="font-size: 14px; margin-right: 10px">
            Please contact
            <span style="font-weight: bold"><a href="mailto:support@goodyearpass.com">support@goodyearpass.com</a></span> if
            you continue to experience errors with your request.
          </p>
        </b-col>
      </b-row>
    </div>
    <template v-slot:buttons>
      <button v-if="isSuccessResult" class="modal-button" v-on:click="closeModal">Ok</button>
      <button v-if="!isSuccessResult" class="modal-button-secondary" style="margin-right: 15px" v-on:click="closeModal">Cancel</button>
      <button v-if="!isSuccessResult" class="modal-button" v-on:click="tryAgain">Try again</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";

export default {
  name: "BulkResultModal",
  data: function () {
    return {
      isSuccessResult: false,
    };
  },
  components: {
    Modal,
  },
  props: {
    bulkResultStatus: Number,
    bulkResultMessageId: String,
    bulkResultOperationType: String
  },
  mounted: async function () {
    if (this.bulkResultStatus === 200) {
      this.isSuccessResult = true;
    }
  },
  methods: {
    closeModal: function () {
      this.$emit("close");
    },
    tryAgain: function () {
      this.$emit("close");
      this.$emit(this.bulkResultOperationType);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alert-modal {
  position: absolute;
  background-color: white;
  min-width: 350px;
  max-height: 90%;
  overflow: scroll;
  z-index: 2001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 20px solid white;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.modal-close-button {
  position: absolute;
  color: #0d2f42;
  font-size: 1.2em;
  line-height: 0.75;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
}

.modal-close-button:hover {
  cursor: pointer;
  opacity: 0.5;
}

.modal-body-wrapper {
  margin-top: 35px;
}

.modal-btns {
  display: flex;
  justify-content: center;
  margin: auto;
  height: 35px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.modal-overlay {
  position: absolute;
  background-color: rgb(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 2000;
}

:deep(.modal-text) {
  width: 100%;
  font-size: 16px;
  text-align: center;
}

:deep(.modal-button) {
  font-size: 16px;
  font-weight: 600;
  background-color: #0d2f42;
  border: #0d2f42 2px solid;
  color: white;
  border-radius: 20px;
  padding: 4px 14px;
  transition: 0.2s;
}

:deep(.modal-button:hover) {
  opacity: 0.5;
}

:deep(.modal-button:disabled) {
  opacity: 0.5;
}

:deep(.modal-button-secondary) {
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  border: #0d2f42 2px solid;
  color: #0d2f42;
  border-radius: 20px;
  padding: 4px 14px;
  transition: 0.2s;
}

:deep(.modal-button-secondary:hover) {
  opacity: 0.5;
}
</style>
