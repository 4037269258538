<template>
     <Modal @close="close">        
      <form enctype="multipart/form-data" novalidate>
        <h3 style="margin-bottom: 20px; text-align: center;"><strong>{{ this.currentApi.name }}</strong> API</h3>    
        <div>
          <b-dropdown :disabled="this.loadingInProgress" id="dropdown-1" text="Choose other API" variant="primary" toggle-class="text-decoration-none" no-caret>
            <b-dropdown-item v-for="api in apis" v-bind:key="api.id" v-on:click="setCurrentAPI(api.id)">{{ api.name }}</b-dropdown-item>
          </b-dropdown>
        </div>  
        <b-row class="tableTitle-wrapper">
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="tableTitle">
            <p><strong>Add a Permission</strong></p>
            <div class="modal-text" style="text-align: left;">Define the permissions (scopes) that this API uses.</div>
          </b-col>
        </b-row>
        <b-row class="form-row-wrapper">
          <b-col cols="3" sm="4">
            <label for="permissionName" class="modal-text" style="text-align: left;"><strong>* Permission</strong></label>
            <b-form-input v-model="permissionName" type="text" name="permissionName" placeholder="Enter Permission"></b-form-input>
          </b-col>
          <b-col cols="3" sm="4">
            <label for="permissionDescription" class="modal-text" style="text-align: left;"><strong>* Description</strong></label>
            <b-form-input v-model="permissionDescription" type="text" name="permissionDescription" placeholder="Enter Description"></b-form-input>
          </b-col>
          <b-col cols="3" sm="4">
            <b-button style="margin-top: 30px;" :disabled="this.loadingInProgress" v-on:click="addPermission()" class="primary-button">
              <b-icon icon="plus-circle-fill" style="margin-right: 8px;"></b-icon> Add
            </b-button>
          </b-col>
        </b-row>
        <b-row class="tableTitle-wrapper">
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="tableTitle">
            <p><strong>List of Permissions</strong></p>
            <div class="modal-text" style="text-align: left;">These are all the permissions that this API uses.</div>
          </b-col>
        </b-row>
          <b-row class="table-wrapper">
            <table class="table">
              <caption style="display: none;"></caption>
              <th></th>
              <tr class="tableHeader">
                <td class="tableHeader-column1">
                  <div class="tableHeader-title">Permission</div>
                </td>
                <td class="tableHeader-column2">
                  <div class="tableHeader-title">Description</div>
                </td>
                <td class="tableHeader-column6">
                </td>    
              </tr>
              <tr class="spacer" style="border-top: none;"> 
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <div class="tableRow-wrapper">
                <tr class="tableRow" v-for="permission in this.permissions" v-bind:key="permission.value">
                  <td v-if="this.permissions.length > 0" class="tableData">{{ permission.value }}</td>
                  <td v-if="this.permissions.length > 0" class="tableData">{{ permission.description }}</td>
                  <td v-if="this.permissions.length > 0">
                    <b-button :disabled="this.loadingInProgress" style="background-color: transparent; border: none; cursor: pointer;" v-on:click="removePermission(permission.value)">
                      <b-icon icon="trash" font-scale="1.7" style="color: red;"></b-icon>
                    </b-button>
                  </td>      
                </tr>
              </div>

              <tr v-if="this.permissions.length == 0">
                <strong>This API does not contain any permissions.</strong>
              </tr>
            </table>
          </b-row>
    </form>
  
    <template v-slot:buttons>
      <button class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close">Close</button>
    </template>
    
  </Modal>
</template>

<script>
import Modal from "./Modal";
import axios from 'axios';
import { apiPath } from '../../authConfig';
axios.defaults.baseURL = apiPath;

export default {
  name: "ManagePersmissionsModal",
  components: {
    Modal,
  }, 
  props: {
    apis: Array
  },
  data: function () {
    return {
      currentApi: {},
      permissions: [],
      permissionName: "",
      permissionDescription: "",
      linkedApis: [],
      loadingInProgress: false,
      isPermissionNameAlreadyExists: false
    }
  },
  computed: {
  },  
  mounted: async function() {
    let currentApiId = -1;
    this.loadingInProgress = true;
    for(const api of this.apis) {
        currentApiId = this.apis[0].id;
        this.linkedApis.push(api);
    }
    await this.setCurrentAPI(currentApiId);
    this.loadingInProgress = false;   
  },
  methods: { 
    setCurrentAPI: async function(id) {
      this.loadingInProgress = true;
      this.permissions = [];
      for(const api of this.linkedApis) {
        if(api.id === id){
          this.currentApi = api;
          for(const apiscope of api.scopes){
            this.permissions.push(apiscope);
          }
        }
      }
      this.loadingInProgress = false;
    },
    addPermission: async function() {

      this.isPermissionNameAlreadyExists = false;

      if(this.permissionName.length === 0) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Please enter a Permission Name.`
        });
        return;
      } else if(this.permissionDescription.length === 0) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Please enter a Permission Description.`
        });
        return;
      } else {
        this.permissionNameAlreadyExists();
        if(this.isPermissionNameAlreadyExists) {
          this.$store.dispatch('alerts/openErrorAlert', {
          content: `Permission already exists.`
        });
          return;
        }        
      }

      this.loadingInProgress = true;
      
      let pToAdd = {
        "value": this.permissionName.trim(),
        "description": this.permissionDescription.trim()
      };

      this.currentApi.scopes.push(pToAdd);

        await this.setApiPermissions();

        await this.setAppPermissions();

        this.permissions.push(pToAdd);

        this.$emit('add-permission-completed');

        this.loadingInProgress = false;

        this.$store.dispatch('alerts/openSuccessAlert', {
          content: `Permission successfully added.`
        });

        this.permissionName = "";
        this.permissionDescription = "";

    },
    removePermission: async function(name) {

      this.loadingInProgress = true;

      let currScopes = this.currentApi.scopes;

      for (let i = 0; i < currScopes.length; i++) {
        if (currScopes[i].value === name) {
            currScopes.splice(i, 1);
        }
      }

        await this.setApiPermissions();

        await this.setAppPermissions();

        currScopes = this.permissions;

      for (let i = 0; i < currScopes.length; i++) {
        if (currScopes[i].value === name) {
            currScopes.splice(i, 1);
        }
      }

      this.$emit('remove-permission-completed');

      this.loadingInProgress = false;

      this.$store.dispatch('alerts/openErrorAlert', {
        content: `Permission successfully removed.`
      });

    },    
    permissionNameAlreadyExists: async function() {
      for(const permission of this.permissions){
        if(this.permissionName.toUpperCase().trim() === permission.value.toUpperCase()) {
          this.isPermissionNameAlreadyExists = true;
        }
      }
    },
    setApiPermissions: async function() {
      let data = {
          "scopes": this.currentApi.scopes
        };

        await axios.patch('/adminportal/applications/' + this.$store.state.groupId + '/apis/' + this.currentApi.id + '/permissions', data, {          
              headers: {
                'Authorization': this.$store.state.token,
                'Content-Type': 'application/json'
              }
            }
          ).then(response => {
            this.loadingInProgress = false;
          })
          .catch(() => {
            this.loadingInProgress = false;
          });
    },
    setAppPermissions: async function() {
      let appApis = [];
      for(const api of this.linkedApis) {
        let appApi = {};
        appApi[api.id] = {
          identifier: api.identifier,
          permissions: []
        };
        let scopes = api.scopes;
        if(api.id === this.currentApi.id){
          scopes = this.currentApi.scopes;
        }
        for(const apiScope of scopes){
            appApi[api.id].permissions.push(apiScope.value);  
        }
        appApis.push(appApi);
      }
      
      let data = {
	      "permissions": appApis
      };        
        await axios.patch('/adminportal/applications/' + this.$store.state.groupId + '/permissions', data, {
            headers: {
              'Authorization': this.$store.state.token,
              'Content-Type': 'application/json'
            }
          }
        ).then(response => {
            this.loadingInProgress = false;
          })
          .catch(() => {
            this.loadingInProgress = false;
          });
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .primary-button {
    background-color: #0D2F42;
    color: white;
    font-size: 14px !important;
    font-weight: 600;
    border-radius: 50px;
    transition: 0.25s;
    transition: 0.2s;
  }

  .primary-button:hover {
    color: white;
    opacity: 0.5;
  }
  
  .tableTitle-wrapper {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .tableTitle {
    font-size: 20px;
    color: #0D2F42;
  }

  .tableRow-wrapper {
    display: table-row-group;
  }

  .tableRow:nth-child(odd) {
    background-color: rgba(13, 47, 66, 0.15);
  } 

  .tableRow:nth-child(even) {
    background-color: white;
  }

  .tableRow {
    border-bottom: 1.5px solid #0D2F42;
    transition: 0.15s;
  }

  .tableRow:hover {
    background-color: rgba(13, 47, 66, 0.32);
    cursor:default;
  }

  .tableHeader {
    background-color: #0D2F42;
    color: white;
    margin-bottom: 20px;
  }

  .tableHeader-title {
    margin-left: 15px;
    margin-right: 15px;
  }

  .tableHeader-column1, .tableHeader-column2, .tableHeader-column3, .tableHeader-column4,  .tableHeader-column5,  .tableHeader-column6 {
    padding: 5px;
    border-right: 2px solid;
    border-color: rgba(255, 255, 255, 0.5);
  }

  .tableHeader-column1 {
    border-radius: 50px 0 0 50px;
  }

  .tableHeader-column1, .tableHeader-column2 {
    cursor: pointer;
  }

  .tableHeader-column5, .tableHeader-column6 {
    border-right: 0;
  }

  .tableHeader-column6 {
    border-radius: 0 50px 50px 0;
    width: 50px;
  }

  .tableData {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px 0px 15px 15px;
  }

  .spacer {
    height: 20px;
    border-top: none;
  }

</style>
