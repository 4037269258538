<template>
  <Modal @close="closeModal">
    <h3 style="text-align: center"></h3>
    <div
      v-if="isSuccessResult"
      style="text-align: center; border: green 1px solid"
    >
      <b-row>
        <b-col style="margin-top: 10px" cols="12" sm="12">
          <b-icon icon="check-circle" style="text-align: center" font-scale="1.8"></b-icon>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin-top: 10px; margin-right: 10px" cols="12" sm="12">
          <p style="font-size: 18px">
            <span v-if="addRoleResultRoleId">Role</span><span v-else>New role,</span> <span style="font-weight: bold">{{ addRoleResultRoleName }}</span>, for {{ addRoleResultAppName }}
            has been successfully <span v-if="addRoleResultRoleId">updated</span><span v-else>created</span>.
          </p>
        </b-col>
      </b-row>
    </div>
    <div v-if="!isSuccessResult" style="text-align: center; border: red 1px solid">
      <b-row>
        <b-col style="margin-top: 10px" cols="12" sm="12">
          <b-icon icon="x-circle" style="text-align: center" font-scale="1.8"></b-icon>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin-top: 10px; margin-right: 10px" cols="12" sm="12">
          <p style="font-size: 18px">
            There was an error when attempting to <span v-if="addRoleResultRoleId">update the role</span><span v-else>create the new role,</span> <span style="font-weight: bold">{{ addRoleResultRoleName }}</span>, for {{ addRoleResultAppName }}.
            Please refresh and try again.
          </p>
        </b-col>
      </b-row>
    </div>
    <template v-slot:buttons>
      <button class="modal-button" v-on:click="closeModal">Ok</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";

export default {
  name: "AddRoleResultModal",
  data: function () {
    return {
      isSuccessResult: false,
    };
  },
  components: {
    Modal,
  },
  props: {
    addRoleResultStatus: Number,
    addRoleResultRoleName: String,
    addRoleResultAppName: String,
    addRoleResultRoleId: String,
  },
  mounted: async function () {
    if (this.addRoleResultStatus === 200) {
      this.isSuccessResult = true;
    }
  },
  methods: {
    closeModal: function () {
      this.$emit("close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.alert-modal {
  position: absolute;
  background-color: white;
  min-width: 350px;
  max-height: 90%;
  overflow: scroll;
  z-index: 2001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 20px solid white;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.modal-close-button {
  position: absolute;
  color: #0d2f42;
  font-size: 1.2em;
  line-height: 0.75;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
}

.modal-close-button:hover {
  cursor: pointer;
  opacity: 0.5;
}

.modal-body-wrapper {
  margin-top: 35px;
}

.modal-btns {
  display: flex;
  justify-content: center;
  margin: auto;
  height: 35px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.modal-overlay {
  position: absolute;
  background-color: rgb(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 2000;
}

</style>
