<template>
  <Modal @close="close">
    <form enctype="multipart/form-data" novalidate>
      <h3 v-if="!deleteRoleLoading" style="margin-bottom: 20px;">Are you sure you want to delete {{ deleteRoleRoleName }} role from {{ deleteRoleAppName }}</h3>
      <h3 v-if="deleteRoleLoading" style="margin-bottom: 20px; margin-right: 250px;">Please wait...</h3>
      <b-row>
        <b-col style="margin-top: 10px; margin-right: 10px" cols="12" sm="12">
          <p style="font-size: 14px;">
            Please type the role name to confirm deletion.
          </p>
        </b-col>
      </b-row>
            <b-row class="form-row-wrapper">
              <b-col cols="12" sm="12" md="10" lg="8" xl="8">
                <label for="roleName" class="b2c-label">* Role Name</label>
                <b-form-input :disabled="deleteRoleLoading" v-bind:class="[roleNameMatches ? 'b2c-form-input' : 'b2c-form-input-error']" v-model="roleName" type="text" id="roleName" name="roleName" placeholder="Enter Role Name"></b-form-input>
              </b-col>
            </b-row>
    </form>

    <template v-slot:buttons>
      <button class="modal-button-secondary" :disabled="deleteRoleLoading" style="margin-right: 15px;" v-on:click="close">No, cancel.</button>
      <button v-if="!deleteRoleLoading" class="modal-button" :disabled="!roleNameMatches" v-on:click="deleteRole">Yes, delete.</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import axios from 'axios';
import { apiPath } from '../../authConfig';
axios.defaults.baseURL = apiPath;

export default {
  name: "DeleteRoleModal",
  components: {
    Modal,
  }, 
  props: {
    deleteRoleRoleName: String,
    deleteRoleAppName: String,
  },
  data: function () {
    return {
      deleteRoleSuccess:false,
      deleteRoleError:false,
      deleteRoleFails:[],
      deleteRoleLoading: false,
      deleteRoleComplete: false,
      roleName: ""
    }
  },
  computed: {
    roleNameMatches: function() {
        return this.roleName == this.deleteRoleRoleName;
    }
  },
  methods: {

    deleteRole: async function() {

      this.deleteRoleLoading = true;

      let data = {
        "name": this.roleName
      }

      await axios.delete('/adminportal/applications/' + this.$store.state.groupId + '/roles',
      {
        headers:{
          Authorization: this.$store.state.token,
          'Content-Type': 'application/json'
        },
        data
      })
      .then(response => {
          this.deleteRoleSuccess = true;
          this.deleteRoleLoading = false;          
          this.deleteRoleComplete = true;
          this.$emit('delete-role-completed');
          this.$emit('delete-role-result-completed', response.status, this.roleName);
          this.$emit('close');

      })
      .catch(() => {
        this.deleteRoleError = true;
        this.deleteRoleComplete = true;
        this.deleteRoleLoading = false;
        this.$emit('delete-role-result-completed', 500, this.roleName);
        this.$emit('close');
      })
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  .b2c-form-input {
    color: #0D2F42;
    font-size: 1em;
    font-weight: 400;
    white-space: nowrap;
  }

  .b2c-form-input-error {
    border: red solid 2px;
    background-color: rgba(255, 0, 0, 0.095);
  }

  .dropbox {
    outline: 2px dashed #0D2F42;
    border-radius: 20px;
    outline-offset: -10px;
    background: #0d2f4207;
    color: #0D2F42;
    padding: 10px 10px;
    min-height: 100px;
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    max-height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: #0d2f421a;
  }
  
  .dropbox p {
    text-align: center;
    padding: 10px 0;
  }

  .parsed-users-table-wrapper {
    max-height: 200px;
    overflow: scroll;
  }

  .parsed-users-table-title {
    color: #C50202;
    margin: 20px 0px;
  }

  .parsed-users-table {
    width: 100%;
  }

  .parsed-users-table tr:nth-child(odd) {
    background-color: rgba(13, 47, 66, 0.2);
  }

  .parsed-users-table tr:nth-child(even) {
    background-color: rgba(13, 47, 66, 0.05);
  }

  .parsed-users-table td {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .bulkInviteError {
    color: #C50202;
  }

  .load-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: -18px;
  }

  .loadingMsg {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
  }

</style>
