<template>
  <Modal @close="close">
    <div class="modal-text">Are you sure you want to remove these members? </div><br>
    <div class="modal-text" v-for="email in membersToDelete" v-bind:key="email">
      <strong>{{ email }}</strong>
    </div>
    <template v-slot:buttons>
      <button class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close()">Cancel</button>
      <button class="modal-button" v-on:click.once="removeMembers()">Delete</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import axios from 'axios';
import { apiPath } from '../../authConfig';
axios.defaults.baseURL = apiPath;

export default {
  name: "RemoveMembersModal",
  components: {
    Modal,
  },
  props: {
    membersToDelete: Array,
  },
  methods: {
    removeMembers: async function() {

      await axios.delete('/adminportal/applications/' + this.$store.state.groupId + '/members',
      {   
        headers:{
          'Content-Type': 'application/json',
          Authorization: this.$store.state.token
        },
        data: {
          emails: this.membersToDelete
        }
      })
      .then(response => {
        const responseStatus = response.data.result.status;
        // 200 all users succeeded
        if(responseStatus === 'succeeded') {
          this.$store.dispatch('alerts/openSuccessAlert', {
            content: `Members successfully removed from ${this.$store.state.groupName}.`
          });
          this.$emit('members-removed');
         
          // 206 partial success - some users failed
        } else if(responseStatus === 'partialSuccess') {
          this.$store.dispatch('alerts/openErrorAlert', {
            content: `Error when removing some members from ${this.$store.state.groupName}. Please refresh and try again.`
          });
          this.$emit('members-remove-partial')
          
        // 400 all users failed
        } else if(responseStatus === 'failed') {
          this.$store.dispatch('alerts/openErrorAlert', {
            content: `Error when removing members from ${this.$store.state.groupName}. Please refresh and try again.`
          });
          this.$emit('members-remove-failed')
        }
       

      })
      .catch(error => {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Error when removing members from ${this.$store.state.groupName}. Please refresh and try again.`
        });
        this.$emit('members-remove-failed')
      })
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>
