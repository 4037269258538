import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/components/Home'
import Login from '@/components/login'
import error404 from '@/components/404'
import Logout from '@/components/logout'
import AccessDenied from '@/components/AccessDenied'
import { getAuthInstance } from '@/auth'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/404-error',
      name: 'error-404',
      component: error404
    },
    {
      path: '/access-denied',
      name: 'access-denied',
      component: AccessDenied
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404-error'
    }
  ]
})

router.beforeEach(async (to, _from, next) => {

  const authInstance = getAuthInstance();

  if (!authInstance.auth0Client) {
    await authInstance.initialize();
  }

  if (to.name === 'error-404') {
    next();
  } else if (to.name === 'Logout') {
    if (authInstance.isAuthenticated) {
      // user logged in - log out
      await authInstance.logout();
    } else {
      next();
    }
  } else if (to.name === 'Login') {
    if (authInstance.isAuthenticated) {
      // user logged in - send to main page
      next({ name: 'Home' })
    } else {
      // user not logged in
      //next();
      await authInstance.loginWithRedirect();
      next();
    }
  } else {
    if (authInstance.isAuthenticated) {
      // user logged in
      next();
    } else {
      // user not logged in - signout which sends to logout page
      await authInstance.loginWithRedirect();
      next();
    }
  }
});

export default router
