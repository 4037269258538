<template>
  <Modal @close="close">
    <h3>Resend Invite</h3>
    <div style="height: 20px;"></div>
    <div v-if="membersToResendInvite.length === 1" style="text-align: center;">
        Are you sure you would like to resend the invite  to <strong>{{ membersToResendInvite[0] }}?</strong>
    </div>
    <div v-else style="text-align: center;">
      Are you sure you would like to resend the invite to the following emails?<br/>
      <div class="users-table-wrapper" v-if="membersToResendInvite.length > 0">
        <table class="users-table">
          <caption style="display: none;"></caption>
            <th></th>
            <tr v-for="email in membersToResendInvite" v-bind:key="email">
                <td>
                    {{ email }}
                </td>
            </tr>
        </table>
      </div>
    </div>
    <template v-slot:buttons>
      <div>
        <button class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close">Cancel</button>
        <button class="modal-button" :disabled="membersToResendInvite.length == null || membersToResendInvite.length == 0 || resendInviteLoading" v-on:click="resendInvite(membersToResendInvite, membersToResendInviteStatus)">Resend</button>
        
        <div class="resendInviteLoading" v-if="resendInviteLoading">
          <div class="d-flex load-wrapper">
            <b-icon icon="arrow-repeat" animation="spin-pulse" font-scale="1"></b-icon>
            <div class="loadingMsg">Loading...</div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import axios from 'axios';
import { apiPath } from '../../authConfig';
axios.defaults.baseURL = apiPath;

export default {
  name: "ResendInviteModal",
  components: {
    Modal,
  },
  props: {
    membersToResendInvite: Array,
    membersToResendInviteStatus: Array
  },
  data: function () {
    return {
      successfulInvites: [],
      failedInvites: [],
      resendInviteError: false,
      resendInviteSuccess: false,
      resendInvitePartialSuccess: false,  
      resendInviteLoading: false,
    }
  },
  methods: {
    resendInvite: async function (emails, userStatuses) {
      
      this.resendInviteLoading = true;

      for (let i = 0; i < emails.length; i++) {
        if (userStatuses[i] === 'Pending') {
          await axios.post('/adminportal/applications/' + this.$store.state.groupId + '/resend_invite', {
            email: emails[i]
          },
            {
              headers: {
                Authorization: this.$store.state.token,
                'Content-Type': 'application/json'
              }
            }
          )
            .then(response => {
              if (response.data) {
                this.successfulInvites.push(emails[i])
              } else {
                this.failedInvites.push(emails[i]);
              }
            })
            .catch(() => {
              this.failedInvites.push(emails[i]);
            })
        } else {
          this.failedInvites.push(emails[i]);
        }
      }

      this.resendInviteLoading = false;

      if (this.failedInvites.length === emails.length) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Failed to reinvite member(s) to ${this.$store.state.groupName}.`
        });
      } else if (this.successfulInvites.length === emails.length) {
        this.$store.dispatch('alerts/openSuccessAlert', {
          content: `Member(s) have been successfully reinvited to ${this.$store.state.groupName}!`
        });
      } else {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `<div>Failed to reinvite <strong>some members</strong> to ${this.$store.state.groupName}:</div>
                    <strong>${this.failedInvites.join('<br/>')}</strong>`
        });
      }
      this.$emit('invites-resend-completed')
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>

<style scoped>
  .load-wrapper {
    padding: 10px;
    display: flex;
  }
  .resendInviteLoading {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .loadingMsg {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
  }
  .users-table-wrapper {
    max-height: 200px;
    overflow: scroll;
  }

  .users-table-title {
    color: #C50202;
    margin: 20px 0px;
  }

  .users-table {
    width: 100%;
  }

  .users-table tr:nth-child(odd) {
    background-color: rgba(13, 47, 66, 0.2);
  }

  .users-table tr:nth-child(even) {
    background-color: rgba(13, 47, 66, 0.05);
  }

  .users-table td {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
  }
</style>
