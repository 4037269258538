import Vue, { createApp } from 'vue'
import { BootstrapVue, BootstrapVueIcons, TooltipPlugin, BTooltip } from 'bootstrap-vue'
import Paginate from 'vuejs-paginate'
import App from './App.vue'
import router from './router'
import { store } from './store'
import { domain, clientId } from './authConfig'
import { Auth0Plugin } from './auth'
import VueDOMPurifyHTML from 'vue-dompurify-html';

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import '../node_modules/vue-multiselect/dist/vue-multiselect.css'
import '@/assets/styles/style.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

const app = createApp({
  ...App
})

app.use(store);
app.use(router)
app.use(TooltipPlugin)
app.use(VueDOMPurifyHTML);

app.component('b-tooltip', BTooltip)
app.component('paginate', Paginate)

app.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
})

app.mount('#app')
