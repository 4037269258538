<template>
  <Modal @close="close">
    <form enctype="multipart/form-data" novalidate>
      <b-row class="form-row-wrapper">
        <b-col cols="12" sm="12">
          <h3 v-if="this.addRoleLoading" style="margin-bottom: 20px; margin-right: 250px;">Please wait...</h3>
          <h3 v-if="!roleId && !this.addRoleLoading" style="margin-bottom: 20px; margin-right: 250px;">Add New role</h3>
          <h3 v-if="roleId && !this.addRoleLoading" style="margin-bottom: 20px; margin-right: 250px;">Edit role</h3>
        </b-col>
      </b-row>
      <b-row class="form-row-wrapper">
        <b-col cols="12" sm="12">
           <label for="roleName" class="b2c-label">* Role Name</label>
           <b-form-input :disabled="this.addRoleLoading" v-model="roleName" type="text" name="roleName" placeholder="Enter Role Name"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="form-row-wrapper">
         <b-col cols="12" sm="12">
            <label for="roleDescription" class="b2c-label">* Role Description</label>
            <b-form-textarea class="role-description__textarea" :disabled="this.addRoleLoading" v-model="roleDescription" type="textarea" name="roleDescription" placeholder="Enter Role Description"></b-form-textarea>
          </b-col>
      </b-row>
    </form>

    <template v-slot:buttons>
      <button :disabled="this.addRoleLoading" class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close">Cancel</button>
      <button v-if="!roleId && !this.addRoleLoading" class="modal-button" v-on:click="addRole">Create new role</button>
      <button v-if="roleId && !this.addRoleLoading" class="modal-button" v-on:click="updateRole">Update role</button>       
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import axios from 'axios';
import { apiPath } from '../../authConfig';
axios.defaults.baseURL = apiPath;

export default {
  name: "AddRoleModal",
  components: {
    Modal,
  },
  data: function () {
    return {
      addRoleSuccess:false,
      addRoleError:false,
      addRoleFails:[],
      addRoleLoading: false,
      addRoleComplete: false,
      roleId: '',
      roleName: '',
      roleDescription: '',
      roles: [],
    }
  }, 
  props: {
    addRoleRoleId: String
  },
  mounted: async function () {

    this.addRoleLoading = true;

    if (this.addRoleRoleId) {
      await axios.get('/adminportal/applications/' + this.$store.state.groupId + '/roles', {
        headers: { Authorization: this.$store.state.token }
      })
      .then(response => {
        this.roles = response.data.result.roles;
      });

      for(const role of this.roles) {
        if(this.addRoleRoleId === role.id) {
          this.roleId = role.id;
          this.roleName = role.name;
          this.roleDescription = role.description;
        }
      }      
    }

    this.addRoleLoading = false;
  },
  methods: {

    addRole: async function() {

      this.addRoleLoading = true;

      if(this.roleName.length === 0 || this.roleName.length > 256 || !(/^[a-zA-Z0-9-_\ ]+$/.test(this.roleName))) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Please enter a valid Role Name.`
        });
        this.addRoleLoading = false;
        return;
      }else if(this.roleDescription.length === 0 || this.roleDescription.length > 1024) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Please enter a valid Role Description.`
        });
        this.addRoleLoading = false;
        return;
      }  

      let rn = this.roleName.trim();
      let rd = this.roleDescription.trim();

      let data = {
        "name": rn,
        "description": rd
      }

      await axios.post('/adminportal/applications/' + this.$store.state.groupId + '/roles', data,
      {
        headers:{
          Authorization: this.$store.state.token,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
          this.addRoleSuccess = true;
          this.addRoleLoading = false;          
          this.addRoleComplete = true;
          this.$emit('add-role-completed');
          this.$emit('add-role-result-completed', response.status, this.roleName);
          this.$emit('close');

      })
      .catch(() => {
        this.addRoleError = true;
        this.addRoleComplete = true;
        this.addRoleLoading = false;
        this.$emit('add-role-result-completed', 500, this.roleName);
        this.$emit('close');
      })
    },
    updateRole: async function() {

      this.addRoleLoading = true;

      if(this.roleName.length === 0 || this.roleName.length > 256 || !(/^[a-zA-Z0-9-_\ ]+$/.test(this.roleName))) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Please enter a valid Role Name.`
        });
        this.addRoleLoading = false;
        return;
      }else if(this.roleDescription.length === 0 || this.roleDescription.length > 1024) {
        this.$store.dispatch('alerts/openErrorAlert', {
          content: `Please enter a valid Role Description.`
        });
        this.addRoleLoading = false;
        return;
      }  

      let rn = this.roleName.trim();
      let rd = this.roleDescription.trim();

      let data = {
        "name": rn,
        "description": rd
      }

      await axios.patch('/adminportal/applications/' + this.$store.state.groupId + '/roles/' + this.roleId, data,
      {
        headers:{
          Authorization: this.$store.state.token,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
          this.addRoleSuccess = true;
          this.addRoleLoading = false;          
          this.addRoleComplete = true;
          this.$emit('add-role-completed');
          this.$emit('add-role-result-completed', response.status, this.roleName, this.roleId);
          this.$emit('close');

      })
      .catch(() => {
        this.addRoleError = true;
        this.addRoleComplete = true;
        this.addRoleLoading = false;
        this.$emit('add-role-result-completed', 500, this.roleName, this.roleId);
        this.$emit('close');
      })
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  .dropbox {
    outline: 2px dashed #0D2F42;
    border-radius: 20px;
    outline-offset: -10px;
    background: #0d2f4207;
    color: #0D2F42;
    padding: 10px 10px;
    min-height: 100px;
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    max-height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: #0d2f421a;
  }
  
  .dropbox p {
    text-align: center;
    padding: 10px 0;
  }

  .parsed-users-table-wrapper {
    max-height: 200px;
    overflow: scroll;
  }

  .parsed-users-table-title {
    color: #C50202;
    margin: 20px 0px;
  }

  .parsed-users-table {
    width: 100%;
  }

  .parsed-users-table tr:nth-child(odd) {
    background-color: rgba(13, 47, 66, 0.2);
  }

  .parsed-users-table tr:nth-child(even) {
    background-color: rgba(13, 47, 66, 0.05);
  }

  .parsed-users-table td {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .bulkInviteError {
    color: #C50202;
  }

  .load-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: -18px;
  }

  .loadingMsg {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
  }

  .role-description__textarea {
    resize: vertical;
  }

</style>
