<template>
  <div class="logout" role="main">
    <b-container>
      <b-row>
        <div style="text-align: center; margin-top: 50px; margin-bottom: 50px;">
          <img src="../assets/GYPass_Logo_Blue.png" width="300px" alt="GoodyearPass Logo Dark Blue" />
        </div>
      </b-row>
    </b-container>
    <b-container class="logoutMsg-wrapper">
      <b-row>
        <b-col cols="12">
          <h1 class="logoutMsg">You have been successfully logged out.</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="loginButton-wrapper">
          <button class="loginButton" v-on:click="$auth.loginWithRedirect()">Sign In</button>
        </b-col>
      </b-row>
    </b-container>
    <div class="background-image"></div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  color: #0D2F42;
  font-family: 'Barlow', sans-serif;
}

.logout {
  width: 100vw;
  height: calc(100vh - 52px);
  padding: 0px 10px;
}

.logoutMsg-wrapper {
  background-color: white;
  border-radius: 30px;
  max-width: 600px;
  margin: auto;
  padding: 30px;
}

.logoutMsg {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.loginButton-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.loginButton {
  font-size: 16px;
  font-weight: 600;
  background-color: #0D2F42;
  border: #0D2F42 2px solid;
  color: white;
  border-radius: 20px;
  padding: 4px 14px;
  transition: 0.20s;
}

.background-image {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/B2C_GradientBackground.png');
  background-size: cover;
  background-position: center;
  top: 0;
  z-index: -1;
  margin: 0px -10px;
}
</style>
