<template>
  <Modal @close="close">
    <h3>Discard Updates</h3>
    <div style="height: 20px;"></div>
    <div style="text-align: center;">
      There are unsaved updates to this user's permissions. Are you sure you would like to discard updates and return to application members?
    </div>
    <template v-slot:buttons>
      <div>
        <button class="modal-button-secondary" style="margin-right: 15px;" v-on:click="close">No, cancel</button>
        <button class="modal-button" v-on:click="toggleModalTabs()">Yes, proceed</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
export default {
  name: "CancelUserPermissionsModal",
  components: {
    Modal,
  },
  data: function () {
    return {
    }
  },
  methods: {
    toggleModalTabs: function () {
      this.$store.commit('setModalTab', "members")
      this.$store.commit('setUserMenuClicked', "")
      this.close()
    },
    close: function() {
      this.$emit('close');
    },
  },
  
};
</script>

<style scoped>
  .load-wrapper {
    padding: 10px;
    display: flex;
  }
  .resendInviteLoading {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .loadingMsg {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
  }
  .users-table-wrapper {
    max-height: 200px;
    overflow: scroll;
  }
  .users-table-title {
    color: #C50202;
    margin: 20px 0px;
  }
  .users-table {
    width: 100%;
  }
  .users-table tr:nth-child(odd) {
    background-color: rgba(13, 47, 66, 0.2);
  }
  .users-table tr:nth-child(even) {
    background-color: rgba(13, 47, 66, 0.05);
  }
  .users-table td {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
  }
</style>