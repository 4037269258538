const currentDomain = window.location.origin;
const customLogoutRedirectUri = currentDomain + '/logout';

// array of config options per domain(s)
const gyConfigOptions = [
    {
        domains: [process.env.VUE_APP_GYPASS_DOMAIN_PROD, process.env.VUE_APP_GYPASS_DOMAIN_SWA_PROD],
        api: process.env.VUE_APP_API_PROD,
        authDomain: process.env.VUE_APP_AUTH0_DOMAIN_PROD,
        authClientId: process.env.VUE_APP_AUTH0_CLIENT_ID_PROD
    },
    {
        domains: [process.env.VUE_APP_GYPASS_DOMAIN_TEST, process.env.VUE_APP_GYPASS_DOMAIN_SWA_TEST],
        api: process.env.VUE_APP_API_TEST,
        authDomain: process.env.VUE_APP_AUTH0_DOMAIN_TEST,
        authClientId: process.env.VUE_APP_AUTH0_CLIENT_ID_TEST
    },
    {
        domains: [process.env.VUE_APP_GYPASS_DOMAIN_DEV, process.env.VUE_APP_GYPASS_DOMAIN_SWA_DEV],
        api: process.env.VUE_APP_API_DEV,
        authDomain: process.env.VUE_APP_AUTH0_DOMAIN_DEV,
        authClientId: process.env.VUE_APP_AUTH0_CLIENT_ID_DEV
    },
    {
        domains: [process.env.VUE_APP_GYPASS_DOMAIN_LOCAL, process.env.VUE_APP_GYPASS_DOMAIN_SWA_PROD],
        api: process.env.VUE_APP_API_DEV,
        authDomain: process.env.VUE_APP_AUTH0_DOMAIN_DEV,
        authClientId: process.env.VUE_APP_AUTH0_CLIENT_ID_DEV
    }
]

let gyAuthConfig = gyConfigOptions[0]; // default

// loop to see which domain matches current domain 
for (let gyConfigOption of gyConfigOptions) {
    for(let domain of gyConfigOption.domains) {
        if (domain.indexOf(currentDomain.toLowerCase()) !== -1) {
            // update config
            gyAuthConfig = gyConfigOption;
        } 
    }
}

// initial config
export const domain = gyAuthConfig.authDomain;
export const clientId = gyAuthConfig.authClientId;

//Set the path for api calls
export const apiPath = gyAuthConfig.api;