<template>
  <b-col class="hide-small p-0" xs="2" sm="2" md="2" lg="2" xl="2" style="height: 100%;">
    <div class="user-sidebar-wrapper">
        <div class="user-sidebar">
        <div class="sidebar-logo" v-if="!collapsed"><img :src="require('@/assets/GYPass_Logo_White.png')" width="120" alt="GoodyearPass logo"/></div>
        <div class="userIcon" v-bind:class="[collapsed ? 'userIcon-collapsed' : 'userIcon']">{{ $store.state.user.initials.toUpperCase() }}</div>
        <div class="userName">{{ $store.state.user.displayName }}</div>
        <div class="userEmail">{{ $store.state.user.currentUserEmail }}</div>
        <div v-if="!collapsed" class="logoutButton-wrapper">
            <button class="logoutButton" v-if="$auth.isAuthenticated" v-on:click="$auth.logout()">Sign Out</button>
        </div>
        </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "Sidebar",
  props: ["collapsed"],
  methods: {
    logout: function() {
      this.$auth.logout({ logoutParams: { returnTo: window.location.origin + '/logout?cv' } })
    }
  }  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  .user-sidebar-wrapper {
    height: 100%;
    width: 100%;
    transition: 0.25s;
  }

  .user-sidebar-wrapper-collapsed {
    width: 30%;
    transition: 0.25s;
  }

  .user-sidebar {
    align-self: flex-end;
    height: 100%;
    width: 100%;
    margin-right: 15%;
    background-color: #0D2F42;
    border-radius: 0px 50px 0px 0px;
    bottom: 0px;
  }

  .sidebar-logo {
    display: block;
    width: 120px;
    margin: auto;
    padding-top: 40px;
  }

  .userPhoto {
    width: 80px;
    height: 80px;
    background-size: 155%;
    background-position: -25px -20px;
    background-repeat: no-repeat;
    border-radius: 80px;
    margin: auto;
    margin-top: 30px;
    transition: 0.25s;
  }

  .userIcon {
    width: 80px;
    height: 80px;
    background-color: #C43916;
    color: white;
    font-size: 38px;
    font-weight: 600;
    line-height: 76px;
    text-align: center;
    border-radius: 80px;
    margin: auto;
    margin-top: 30px;
    transition: 0.25s;
    text-transform: uppercase;
  }

  .userIcon-collapsed {
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 28px;
  }

  .userName {
    font-size: 16px;
    font-weight: 500;
    color: white;
    text-align: center;
    margin-top: 5px;
    max-width: 100%;
  }

  .this_is_an_empty_class_for_test_change {
    display: none;
  }

  .userEmail {
    font-size: 14px;
    font-weight: 300;
    color: white;
    text-align: center;
    margin-top: 5px;
    padding: 0px 15px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .logoutButton-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .logoutButton {
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    border: white 2px solid;
    color: #0D2F42;
    border-radius: 20px;
    padding: 4px 14px;
    transition: 0.20s;
  }

  .logoutButton:hover {
    background-color: #0D2F42;
    border: white 2px solid;
    color: white;
  }

  .hide-small {
    display: block;
  }

  @media only screen and (max-width: 767px) {
    .user-sidebar-wrapper {
      display: none;
    }

    .hide-small {
      display: none;
    }
  }

  @media only screen and (min-width: 768px) {
    .user-sidebar-wrapper {
      display: block;
    }
  }
  
</style>
